.spending-form {
    max-width: 400px;
    margin: 20px auto 0;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

select,
input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    margin-top: 5px;
}

button {
    background-color: #54c8ff;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 600;
    width: 150px;
    margin-top: 20px;
}

.button-div {
    width: 100%;
    display: flex;
    justify-content: center;
}