body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.filter-wrapper {
  display: flex;
  justify-content: flex-end;
}

.delete-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 90px;
}

.react-datepicker__input-container {
  display: flex !important;
  justify-content: center;
}

.datepicker-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.react-datepicker__input-container input {
  width: 80%;
  height: 30px;
  text-align: center;
}

.add-button {
  position: fixed;
  bottom: 60px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 10px !important;
}


.custom-table {
  padding-bottom: 100px !important;
}

@media(max-width: 768px) {

  .filter-wrapper {
    justify-content: space-evenly;
  }

  .dropdown-width {
    min-width: 12em !important;
  }

  .ui.grid>.row {
    flex-direction: column !important;
  }
  
  .ui.grid>.row>.column {
    width: 100% !important;
  }
  
  .ui.form {
    margin-bottom: 20px;
  }
}